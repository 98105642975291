import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {checkAuthentication, getCustomerAndUserInfo} from '../../appSlice';
import {getSettingsData, updateLoading} from './settingsSlice';
import {isEqual} from 'lodash';
import {accessLevels, fetchPostAuthSafe, unitsSubmitConversion, unitsDisplayConversion} from '../../app/utils';
import {
  CollapsableSection,
  SettingsSwitch,
  SettingsText,
  SettingsSelect,
  SettingsChipInput,
} from '../../components/GeneralComponents';

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';

function ProductTab(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector((state) => {
    return state.settings.loading;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const customerManagementAccess = useSelector((state) => {
    return state.app.customerManagementAccess;
  });
  const customerViewAccess = useSelector((state) => {
    return state.app.customerViewAccess;
  });
  const unitsSystem = useSelector((state) => {
    return state.app.userSettings.general.units;
  });
  const [units, setUnits] = useState('km');
  const [collapseOpen, setCollapseOpen] = useState({
    management: false,
    product: false,
    general: false,
    landing: false,
    cabview: false,
    cropview: false,
    shopview: false,
    diagnostics: false,
  });

  const [formValues, setFormValues] = useState({
    teamManagement: '',
    productAccessManagement: '',
    taskManagement: '',
    vehicleAttributesManagement: '',
    vehicleStatusManagement: '',
    vehicleFeaturesManagement: '',
    implementManagement: '',
    deviceManagement: '',
    labelManagement: '',
    bluetoothManagement: '',
    generalManagement: '',
    customCalibrationManagement: '',
    landingManagement: '',
    cabviewManagement: '',
    cropviewManagement: '',
    shopviewManagement: '',
    inspectionManagement: '',
    diagnosticsManagement: '',
    reportsManagement: '',
    dashboardManagement: '',
    dashboardScorecardManagement: '',
    zoneInfoRowSpacingManagement: '',
    teamManagementView: '',
    productAccessManagementView: '',
    taskManagementView: '',
    vehicleAttributesManagementView: '',
    vehicleStatusManagementView: '',
    vehicleFeaturesManagementView: '',
    implementManagementView: '',
    deviceManagementView: '',
    labelManagementView: '',
    bluetoothManagementView: '',
    generalManagementView: '',
    customCalibrationManagementView: '',
    landingManagementView: '',
    cabviewManagementView: '',
    cropviewManagementView: '',
    shopviewManagementView: '',
    inspectionManagementView: '',
    diagnosticsManagementView: '',
    reportsManagementView: '',
    dashboardManagementView: '',
    dashboardScorecardManagementView: '',
    zoneInfoRowSpacingManagementView: '',
    cabviewEnabled: false,
    cropviewEnabled: false,
    shopviewEnabled: false,
    inspectionEnabled: false,
    zoneInfoEnabled: false,
    dashboardEnabled: false,
    geoFencesProvided: false,
    server: '',
    pipedriveOrganizationId: '',
    timeZone: '',
    hourOffSet: '',
    minuteOffSet: '',
    outOfFieldTime: false,
    idleTime: false,
    rowPassStatisticsEnabled: false,
    idleTimeTriggerThresh: '',
    enableTaskAcreageCapping: false,
    enableTaskCycle: false,
    enableTaskRei: false,
    enableReiDisplay: false,
    enableRecApplication: false,
    enableBluetoothTags: false,
    engineHoursUseIgnEngHrEstimation: false,
    zoneEntryAndExitTimeEnabled: false,
    equipmentShowMostRecentLocation: '',
    deviceManagementEnabled: false,
    units: '',
    expirePermanentTaskImplementEmailList: [],
    userCreationNotificationEmails: [],
    notifyUseWithoutInspection: false,
    notificationEmailsUseWithoutInspection: [],
    gpsProcessingEnabled: false,
    gpsProcessingRateSec: '',
    tripTaskProcessingRateSec: '',
    vehicleStatusVisibility: '',
    landingPageEnabled: false,
    scanAutoStartEnabled: false,
    landingPageRedirectCabview: false,
    landingPageRedirectCropview: false,
    landingPageRedirectShopview: false,
    landingPageRedirectInspection: false,
    landingPageRedirectZoneInfo: false,
    kpiEfficiencyEnabled: false,
    kpiTasksLoggedEnabled: false,
    kpiMissingTasksEnabled: false,
    kpiServiceCostsEnabled: false,
    kpiServiceCompletionEnabled: false,
    kpiInspectionRatesEnabled: false,
    kpiMachineUsageEnabled: false,
    kpiIdlingEnabled: false,
    kpiOperationalEfficiencyEnabled: false,
    kpiIdleInstallStatusWarningDisplay: true,
    kpiEfficiencyHoverDetailsEnabled: false,
    kpiIdleHoverDetailsEnabled: false,
    initPlotLineDisplayOn: false,
    vehicleAverageSpeedEnabled: false,
    enableTaskCycleTable: false,
    taskCycleUpcomingWindowDays: '',
    diagnosticsTabEnabled: false,
    nonGoDeviceVehiclesEnabled: false,
    futureEntryWarningDisabled: false,
    useCurrentEnabled: false,
    newServiceMultiVehicleAdminOnly: false,
    addHistoryMultiServiceAdminOnly: false,
    upcomingOdoThresh: 0,
    upcomingEngHrThresh: 0,
    vehicleCreationNotificationEmails: [],
    diagnosticsFilterCodesGo: '',
    diagnosticsFilterCodesSpn: '',
    diagnosticsEnableCodesFmi: '',
    inspectionReportFailureEmails: '',
    inspectionReportAllItemsComplete: false,
    inspectionReportNameInput: false,
    inspectionReportCommentAllFailures: false,
    inspectionFormWideModeEnabled: false,
    rowpassGpsDataUpSampling: false,
    rowpassPointToLineDistanceThreshold: 0,
    rowpassPointToPolygonDistanceThreshold: 0,
    rowpassCrossToRowBearingAngleThreshold: 0,
    rowpassCrossToBearingDistanceMinThreshold: 0,
    rowpassEdgePassAvgSpeedThresholdKph: 0,
    rowpassResampleMinDistanceMeter: 0,
    rowpassCrossPassMaxSpeedExclusionThreshKph: 0,
    rowpassCrosspassMaxDistExclusionThreshM: 0,
    tripTaskTurningBearingThreshRad: 0,
    tripTaskTurningBearingThreshM: 0,
    bufferTripPercentThresh: 0,
    coverageNonEdgePassesThreshold: 0,
    coverageEdgePassesInclusionThreshold: 0,
    coverageRowPassSummMinDurationSec: 0,
    coverageRowPassSummAveSpeedKph: 0,
    coveragePercentFilter: '',
    coverageAreaMinFilter: '',
    coverageDurationMinFilter: '',
    aggRowDurationThreshold: 0.0,
    rowpassMinTripNonBufferPercentPerDay: 0.0,
    rowpassMinTripBufferPercentPerDay: 0.0,
    tripTaskMinTripNonBufferPercentPerDay: 0.0,
    tripTaskMinTripBufferPercentPerDay: 0.0,
    minTripDuration: 0,
    maxTripSpeed: 0,
    rdpEpsilon: 0,
    timeWindowCoverageDays: 0,
    coverageCompletePercentThreshold: 0,
    taskTimeWindowCoverageCompleteDays: 0,
    taskCoverageCompletePercentThreshold: 0,
    reportsGlobalBccEmails: [],
  });

  useEffect(() => {
    // Determine units based on customer units system
    if (unitsSystem == 'imperial') {
      setUnits('mi');
    } else {
      setUnits('km');
    }
  }, [unitsSystem]);

  useEffect(() => {
    resetFormValues();
  }, [customerSettings]);

  function resetFormValues() {
    let upcomingOdoThreshTemp;
    let upcomingOdoThreshForm;
    if (customerSettings.shopview?.upcomingOdoThresh) {
      upcomingOdoThreshTemp = customerSettings.shopview?.upcomingOdoThresh;
    } else {
      upcomingOdoThreshTemp = 500;
    }

    if (unitsSystem == 'metric') {
      upcomingOdoThreshForm = upcomingOdoThreshTemp;
    } else {
      upcomingOdoThreshForm = unitsDisplayConversion(upcomingOdoThreshTemp, 'mi');
    }

    setFormValues((values) => {
      return {
        ...values,
        teamManagement: customerManagementAccess.teamManagement,
        productAccessManagement: customerManagementAccess.productAccessManagement,
        taskManagement: customerManagementAccess.taskManagement,
        vehicleAttributesManagement: customerManagementAccess.vehicleAttributesManagement,
        vehicleStatusManagement: customerManagementAccess.vehicleStatusManagement,
        vehicleFeaturesManagement: customerManagementAccess.vehicleFeaturesManagement,
        implementManagement: customerManagementAccess.implementManagement,
        deviceManagement: customerManagementAccess.deviceManagement,
        labelManagement: customerManagementAccess.labelManagement,
        bluetoothManagement: customerManagementAccess.bluetoothManagement,
        generalManagement: customerManagementAccess.generalManagement,
        customCalibrationManagement: customerManagementAccess.customCalibrationManagement,
        landingManagement: customerManagementAccess.landingManagement,
        cabviewManagement: customerManagementAccess.cabviewManagement,
        cropviewManagement: customerManagementAccess.cropviewManagement,
        shopviewManagement: customerManagementAccess.shopviewManagement,
        inspectionManagement: customerManagementAccess.inspectionManagement,
        diagnosticsManagement: customerManagementAccess.diagnosticsManagement,
        reportsManagement: customerManagementAccess.reportsManagement,
        dashboardManagement: customerManagementAccess.dashboardManagement,
        dashboardScorecardManagement: customerManagementAccess.dashboardScorecardManagement,
        zoneInfoRowSpacingManagement: customerManagementAccess.zoneInfoRowSpacingManagement,
        teamManagementView: customerViewAccess.teamManagement,
        productAccessManagementView: customerViewAccess.productAccessManagement,
        taskManagementView: customerViewAccess.taskManagement,
        vehicleAttributesManagementView: customerViewAccess.vehicleAttributesManagement,
        vehicleStatusManagementView: customerViewAccess.vehicleStatusManagement,
        vehicleFeaturesManagementView: customerViewAccess.vehicleFeaturesManagement,
        implementManagementView: customerViewAccess.implementManagement,
        deviceManagementView: customerViewAccess.deviceManagement,
        labelManagementView: customerViewAccess.labelManagement,
        bluetoothManagementView: customerViewAccess.bluetoothManagement,
        generalManagementView: customerViewAccess.generalManagement,
        customCalibrationManagementView: customerViewAccess.customCalibrationManagement,
        landingManagementView: customerViewAccess.landingManagement,
        cabviewManagementView: customerViewAccess.cabviewManagement,
        cropviewManagementView: customerViewAccess.cropviewManagement,
        shopviewManagementView: customerViewAccess.shopviewManagement,
        inspectionManagementView: customerViewAccess.inspectionManagement,
        diagnosticsManagementView: customerViewAccess.diagnosticsManagement,
        reportsManagementView: customerViewAccess.reportsManagement,
        dashboardManagementView: customerViewAccess.dashboardManagement,
        dashboardScorecardManagementView: customerViewAccess.dashboardScorecardManagement,
        zoneInfoRowSpacingManagementView: customerViewAccess.zoneInfoRowSpacingManagement,
        cabviewEnabled: customerSettings.general.cabviewEnabled,
        cropviewEnabled: customerSettings.general.cropviewEnabled,
        shopviewEnabled: customerSettings.general.shopviewEnabled,
        inspectionEnabled: customerSettings.general.inspectionEnabled,
        zoneInfoEnabled: customerSettings.general.zoneInfoEnabled,
        dashboardEnabled: customerSettings.general.dashboardEnabled,
        geoFencesProvided: customerSettings.general.geoFencesProvided,
        server: customerSettings.general.server,
        pipedriveOrganizationId: customerSettings.general.pipedriveOrganizationId,
        timeZone: customerSettings.general.timeZone,
        hourOffSet: customerSettings.general.hourOffSet,
        minuteOffSet: customerSettings.general.minuteOffSet,
        outOfFieldTime: customerSettings.general.outOfFieldTime,
        idleTime: customerSettings.general.idleTime,
        rowPassStatisticsEnabled: customerSettings.general.rowPassStatisticsEnabled,
        idleTimeTriggerThresh: customerSettings.general.idleTimeTriggerThresh,
        enableTaskAcreageCapping: customerSettings.general.enableTaskAcreageCapping,
        enableTaskCycle: customerSettings.general.enableTaskCycle,
        enableTaskRei: customerSettings.general.enableTaskRei,
        enableReiDisplay: customerSettings.general.enableReiDisplay,
        enableRecApplication: customerSettings.general.enableRecApplication,
        enableBluetoothTags: customerSettings.general.enableBluetoothTags,
        engineHoursUseIgnEngHrEstimation: customerSettings.general.engineHoursUseIgnEngHrEstimation,
        zoneEntryAndExitTimeEnabled: customerSettings.general.zoneEntryAndExitTimeEnabled,
        equipmentShowMostRecentLocation: customerSettings.inspection?.equipmentShowMostRecentLocation || 'None',
        deviceManagementEnabled: customerSettings.general.deviceManagementEnabled,
        units: customerSettings.general.units,
        gpsProcessingEnabled: customerSettings.general.gpsProcessingEnabled,
        gpsProcessingRateSec: customerSettings.general.gpsProcessingRateSec,
        tripTaskProcessingRateSec: customerSettings.general.tripTaskProcessingRateSec,
        vehicleStatusVisibility: customerSettings.general.vehicleStatusVisibility,
        expirePermanentTaskImplementEmailList: customerSettings.general.expirePermanentTaskImplementEmailList,
        userCreationNotificationEmails: customerSettings.general.userCreationNotificationEmails,
        notifyUseWithoutInspection: customerSettings.inspection.notifyUseWithoutInspection,
        notificationEmailsUseWithoutInspection: customerSettings.inspection.notificationEmailsUseWithoutInspection,
        landingPageEnabled: customerSettings.landing.landingPageEnabled,
        scanAutoStartEnabled: customerSettings.landing.scanAutoStartEnabled,
        landingPageRedirectCabview: customerSettings.landing.landingPageRedirects.cabview,
        landingPageRedirectCropview: customerSettings.landing.landingPageRedirects.cropview,
        landingPageRedirectShopview: customerSettings.landing.landingPageRedirects.shopview,
        landingPageRedirectInspection: customerSettings.landing.landingPageRedirects.inspection,
        landingPageRedirectZoneInfo: customerSettings.landing.landingPageRedirects.zoneInfo,
        kpiEfficiencyEnabled: customerSettings.dashboard.kpiEfficiencyEnabled,
        kpiTasksLoggedEnabled: customerSettings.dashboard.kpiTasksLoggedEnabled,
        kpiMissingTasksEnabled: customerSettings.dashboard.kpiMissingTasksEnabled,
        kpiServiceCostsEnabled: customerSettings.dashboard.kpiServiceCostsEnabled,
        kpiServiceCompletionEnabled: customerSettings.dashboard.kpiServiceCompletionEnabled,
        kpiInspectionRatesEnabled: customerSettings.dashboard.kpiInspectionRatesEnabled,
        kpiMachineUsageEnabled: customerSettings.dashboard.kpiMachineUsageEnabled,
        kpiIdlingEnabled: customerSettings.dashboard.kpiIdlingEnabled,
        kpiOperationalEfficiencyEnabled: customerSettings.dashboard.kpiOperationalEfficiencyEnabled,
        kpiIdleInstallStatusWarningDisplay: customerSettings.dashboard.kpiIdleInstallStatusWarningDisplay,
        kpiEfficiencyHoverDetailsEnabled: customerSettings.dashboard.kpiEfficiencyHoverDetailsEnabled,
        kpiIdleHoverDetailsEnabled: customerSettings.dashboard.kpiIdleHoverDetailsEnabled,
        efficiencyDollarsCalculatorEnabled: customerSettings.dashboard.efficiencyDollarsCalculatorEnabled,
        initPlotLineDisplayOn: customerSettings.cabview.initPlotLineDisplayOn,
        vehicleAverageSpeedEnabled: customerSettings.cropview.vehicleAverageSpeedEnabled,
        enableTaskCycleTable: customerSettings.cropview.enableTaskCycleTable,
        taskCycleUpcomingWindowDays: customerSettings.cropview.taskCycleUpcomingWindowDays,
        diagnosticsTabEnabled: customerSettings.shopview.diagnosticsTabEnabled,
        nonGoDeviceVehiclesEnabled: customerSettings.shopview.nonGoDeviceVehiclesEnabled,
        futureEntryWarningDisabled: customerSettings.shopview.futureEntryWarningDisabled,
        useCurrentEnabled: customerSettings.shopview.useCurrentEnabled,
        newServiceMultiVehicleAdminOnly: customerSettings.shopview.newServiceMultiVehicleAdminOnly,
        addHistoryMultiServiceAdminOnly: customerSettings.shopview.addHistoryMultiServiceAdminOnly,
        upcomingOdoThresh: upcomingOdoThreshForm,
        upcomingEngHrThresh: customerSettings.shopview?.upcomingEngHrThresh || 10,
        vehicleCreationNotificationEmails: customerSettings.shopview?.vehicleCreationNotificationEmails,
        diagnosticsFilterCodesGo: customerSettings.diagnostics.diagnosticsFilterCodesGo,
        diagnosticsFilterCodesSpn: customerSettings.diagnostics.diagnosticsFilterCodesSpn,
        diagnosticsEnableCodesFmi: customerSettings.diagnostics.diagnosticsEnableCodesFmi,
        inspectionReportFailureEmails: customerSettings.inspection.inspectionReportFailureEmails,
        inspectionReportAllItemsComplete: customerSettings.inspection.inspectionReportAllItemsComplete,
        inspectionReportNameInput: customerSettings.inspection.inspectionReportNameInput,
        inspectionReportCommentAllFailures: customerSettings.inspection.inspectionReportCommentAllFailures,
        inspectionFormWideModeEnabled: customerSettings.inspection.inspectionFormWideModeEnabled,
        rowpassGpsDataUpSampling: customerSettings.customCalibration.rowpass.gpsDataUpSampling,
        rowpassPointToLineDistanceThreshold: customerSettings.customCalibration.rowpass.pointToLineDistanceThreshold,
        rowpassPointToPolygonDistanceThreshold:
          customerSettings.customCalibration.rowpass.pointToPolygonDistanceThreshold,
        rowpassCrossToRowBearingAngleThreshold:
          customerSettings.customCalibration.rowpass.crossToRowBearingAngleThreshold,
        rowpassCrossToBearingDistanceMinThreshold:
          customerSettings.customCalibration.rowpass.crossToBearingDistanceMinThreshold,
        rowpassEdgePassAvgSpeedThresholdKph: customerSettings.customCalibration.rowpass.edgePassAvgSpeedThresholdKph,
        rowpassResampleMinDistanceMeter: customerSettings.customCalibration.rowpass.resampleMinDistanceMeter,
        rowpassCrossPassMaxSpeedExclusionThreshKph:
          customerSettings.customCalibration.rowpass.crossPassMaxSpeedExclusionThreshKph,
        rowpassCrosspassMaxDistExclusionThreshM:
          customerSettings.customCalibration.rowpass.crosspassMaxDistExclusionThreshM,
        tripTaskTurningBearingThreshRad: customerSettings.customCalibration.triptask.turningBearingThreshRad,
        tripTaskTurningBearingThreshM: customerSettings.customCalibration.triptask.turningBearingThreshM,
        bufferTripPercentThresh: customerSettings.customCalibration.triptask.bufferTripPercentThresh,
        coverageNonEdgePassesThreshold: customerSettings.customCalibration.coverage.nonEdgePassesThreshold,
        coverageEdgePassesInclusionThreshold: customerSettings.customCalibration.coverage.edgePassesInclusionThreshold,
        coverageRowPassSummMinDurationSec: customerSettings.customCalibration.coverage.rowPassSummMinDurationSec,
        coverageRowPassSummAveSpeedKph: customerSettings.customCalibration.coverage.rowPassSummAveSpeedKph,
        coveragePercentFilter: customerSettings.customCalibration.coverage.coveragePercentFilter,
        coverageAreaMinFilter: customerSettings.customCalibration.coverage.coverageAreaMinFilter,
        coverageDurationMinFilter: customerSettings.customCalibration.coverage.coverageDurationMinFilter,
        aggRowDurationThreshold: customerSettings.customCalibration.coverage.aggRowDurationThreshold,
        rowpassMinTripNonBufferPercentPerDay:
          customerSettings.customCalibration.coverage.rowpassMinTripNonBufferPercentPerDay,
        rowpassMinTripBufferPercentPerDay:
          customerSettings.customCalibration.coverage.rowpassMinTripBufferPercentPerDay,
        tripTaskMinTripNonBufferPercentPerDay:
          customerSettings.customCalibration.coverage.tripTaskMinTripNonBufferPercentPerDay,
        tripTaskMinTripBufferPercentPerDay:
          customerSettings.customCalibration.coverage.tripTaskMinTripBufferPercentPerDay,
        minTripDuration: customerSettings.customCalibration.coverage.minTripDuration,
        maxTripSpeed: customerSettings.customCalibration.coverage.maxTripSpeed,
        rdpEpsilon: customerSettings.customCalibration.gps.rdpEpsilon,
        timeWindowCoverageDays: customerSettings.customCalibration.rei.timeWindowCoverageDays,
        coverageCompletePercentThreshold: customerSettings.customCalibration.rei.coverageCompletePercentThreshold,
        taskTimeWindowCoverageCompleteDays:
          customerSettings.customCalibration.taskCycle.taskTimeWindowCoverageCompleteDays,
        taskCoverageCompletePercentThreshold:
          customerSettings.customCalibration.taskCycle.taskCoverageCompletePercentThreshold,
        reportsGlobalBccEmails: customerSettings.reports.reportsGlobalBccEmails,
      };
    });
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setFormValues((values) => {
      return {...values, [name]: value};
    });
  };

  async function handleSubmit() {
    // Set loading state
    dispatch(updateLoading(true));

    let updateDoc = {};
    let options = {};

    // List of update requests
    const requestsList = [];

    // Management Access
    updateDoc = {};
    const managementBooleansAndStrings = Object.keys(customerManagementAccess);

    for (let i = 0; i < managementBooleansAndStrings.length; i++) {
      if (customerManagementAccess[managementBooleansAndStrings[i]] != formValues[managementBooleansAndStrings[i]]) {
        updateDoc[managementBooleansAndStrings[i]] = formValues[managementBooleansAndStrings[i]];
      }
    }

    if (Object.keys(updateDoc).length > 0) {
      options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(updateDoc),
      };
      requestsList.push(
        fetchPostAuthSafe('/settings/updateManagementAccess', options, userSettings.username, userSettings.databaseName)
      );
    }

    // View Access
    updateDoc = {};
    const viewBooleansAndStrings = Object.keys(customerViewAccess);
    const viewBooleansAndStringsForm = viewBooleansAndStrings.map((key) => {
      return key + 'View';
    });

    for (let i = 0; i < viewBooleansAndStrings.length; i++) {
      if (customerViewAccess[viewBooleansAndStrings[i]] != formValues[viewBooleansAndStringsForm[i]]) {
        updateDoc[viewBooleansAndStrings[i]] = formValues[viewBooleansAndStringsForm[i]];
      }
    }

    if (Object.keys(updateDoc).length > 0) {
      options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(updateDoc),
      };
      requestsList.push(
        fetchPostAuthSafe('/settings/updateViewAccess', options, userSettings.username, userSettings.databaseName)
      );
    }

    // General
    let updatedUnits = false;
    updateDoc = {};
    const generalBooleansAndStrings = [
      'cabviewEnabled',
      'cropviewEnabled',
      'shopviewEnabled',
      'inspectionEnabled',
      'zoneInfoEnabled',
      'dashboardEnabled',
      'geoFencesProvided',
      'server',
      'pipedriveOrganizationId',
      'timeZone',
      'outOfFieldTime',
      'idleTime',
      'rowPassStatisticsEnabled',
      'enableTaskAcreageCapping',
      'enableTaskCycle',
      'enableTaskRei',
      'enableReiDisplay',
      'enableRecApplication',
      'enableBluetoothTags',
      'engineHoursUseIgnEngHrEstimation',
      'zoneEntryAndExitTimeEnabled',
      'deviceManagementEnabled',
      'units',
      'gpsProcessingEnabled',
      'vehicleStatusVisibility',
    ];
    const generalNums = [
      'hourOffSet',
      'minuteOffSet',
      'idleTimeTriggerThresh',
      'gpsProcessingRateSec',
      'tripTaskProcessingRateSec',
    ];

    const generalStringArrays = ['expirePermanentTaskImplementEmailList', 'userCreationNotificationEmails'];

    for (let i = 0; i < generalBooleansAndStrings.length; i++) {
      if (customerSettings.general[generalBooleansAndStrings[i]] != formValues[generalBooleansAndStrings[i]]) {
        updateDoc[generalBooleansAndStrings[i]] = formValues[generalBooleansAndStrings[i]];
      }
    }
    for (let i = 0; i < generalNums.length; i++) {
      const parsedNum = parseFloat(formValues[generalNums[i]]);
      if (customerSettings.general[generalNums[i]] != parsedNum) {
        updateDoc[generalNums[i]] = parsedNum;
      }
    }

    for (let i = 0; i < generalStringArrays.length; i++) {
      if (new Set(customerSettings.general[generalStringArrays[i]]) != new Set(formValues[generalStringArrays[i]])) {
        updateDoc[generalStringArrays[i]] = formValues[generalStringArrays[i]];
      }
    }

    if (typeof updateDoc.units != 'undefined') {
      updatedUnits = true;
    }

    if (Object.keys(updateDoc).length > 0) {
      options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(updateDoc),
      };
      requestsList.push(
        fetchPostAuthSafe('/settings/updateGeneralControl', options, userSettings.username, userSettings.databaseName)
      );
    }

    // Custom Calibration - Rowpass
    updateDoc = {};

    const newRowpassCalibration = {
      gpsDataUpSampling: formValues.rowpassGpsDataUpSampling,
      pointToLineDistanceThreshold: parseFloat(formValues.rowpassPointToLineDistanceThreshold),
      pointToPolygonDistanceThreshold: parseFloat(formValues.rowpassPointToPolygonDistanceThreshold),
      crossToRowBearingAngleThreshold: parseFloat(formValues.rowpassCrossToRowBearingAngleThreshold),
      crossToBearingDistanceMinThreshold: parseFloat(formValues.rowpassCrossToBearingDistanceMinThreshold),
      edgePassAvgSpeedThresholdKph: parseFloat(formValues.rowpassEdgePassAvgSpeedThresholdKph),
      resampleMinDistanceMeter: parseFloat(formValues.rowpassResampleMinDistanceMeter),
      crossPassMaxSpeedExclusionThreshKph: parseFloat(formValues.rowpassCrossPassMaxSpeedExclusionThreshKph),
      crosspassMaxDistExclusionThreshM: parseFloat(formValues.rowpassCrosspassMaxDistExclusionThreshM),
    };

    if (Object.prototype.hasOwnProperty.call(customerSettings.customCalibration, 'rowpass')) {
      Object.keys(newRowpassCalibration).forEach((key) => {
        if (customerSettings.customCalibration.rowpass[key] != newRowpassCalibration[key]) {
          if (typeof updateDoc.rowpass === 'undefined') {
            updateDoc.rowpass = {};
          }
          updateDoc.rowpass[key] = newRowpassCalibration[key];
        }
      });
    }

    if (Object.keys(updateDoc).length > 0) {
      options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(updateDoc),
      };
      requestsList.push(
        fetchPostAuthSafe(
          '/settings/updateCustomCalibration',
          options,
          userSettings.username,
          userSettings.databaseName
        )
      );
    }

    // Custom Calibration - Triptask
    updateDoc = {};

    const newTripTaskCalibration = {
      turningBearingThreshRad: parseFloat(formValues.tripTaskTurningBearingThreshRad),
      turningBearingThreshM: parseFloat(formValues.tripTaskTurningBearingThreshM),
      bufferTripPercentThresh: parseFloat(formValues.bufferTripPercentThresh),
    };

    if (Object.prototype.hasOwnProperty.call(customerSettings.customCalibration, 'triptask')) {
      Object.keys(newTripTaskCalibration).forEach((key) => {
        if (customerSettings.customCalibration.triptask[key] != newTripTaskCalibration[key]) {
          if (typeof updateDoc.triptask === 'undefined') {
            updateDoc.triptask = {};
          }
          updateDoc.triptask[key] = newTripTaskCalibration[key];
        }
      });
    }

    if (Object.keys(updateDoc).length > 0) {
      options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(updateDoc),
      };
      requestsList.push(
        fetchPostAuthSafe(
          '/settings/updateCustomCalibration',
          options,
          userSettings.username,
          userSettings.databaseName
        )
      );
    }

    // Custom Calibration - Coverage
    updateDoc = {};

    const newCoverageCalibration = {
      nonEdgePassesThreshold: parseFloat(formValues.coverageNonEdgePassesThreshold),
      edgePassesInclusionThreshold: parseFloat(formValues.coverageEdgePassesInclusionThreshold),
      rowPassSummMinDurationSec: parseFloat(formValues.coverageRowPassSummMinDurationSec),
      rowPassSummAveSpeedKph: parseFloat(formValues.coverageRowPassSummAveSpeedKph),
      coveragePercentFilter: parseFloat(formValues.coveragePercentFilter),
      coverageAreaMinFilter: parseFloat(formValues.coverageAreaMinFilter),
      coverageDurationMinFilter: parseFloat(formValues.coverageDurationMinFilter),
      aggRowDurationThreshold: parseFloat(formValues.aggRowDurationThreshold),
      rowpassMinTripNonBufferPercentPerDay: parseFloat(formValues.rowpassMinTripNonBufferPercentPerDay),
      rowpassMinTripBufferPercentPerDay: parseFloat(formValues.rowpassMinTripBufferPercentPerDay),
      tripTaskMinTripNonBufferPercentPerDay: parseFloat(formValues.tripTaskMinTripNonBufferPercentPerDay),
      tripTaskMinTripBufferPercentPerDay: parseFloat(formValues.tripTaskMinTripBufferPercentPerDay),
      minTripDuration: parseFloat(formValues.minTripDuration),
      maxTripSpeed: parseFloat(formValues.maxTripSpeed),
    };

    if (Object.prototype.hasOwnProperty.call(customerSettings.customCalibration, 'coverage')) {
      Object.keys(newCoverageCalibration).forEach((key) => {
        if (customerSettings.customCalibration.coverage[key] != newCoverageCalibration[key]) {
          if (typeof updateDoc.coverage === 'undefined') {
            updateDoc.coverage = {};
          }
          updateDoc.coverage[key] = newCoverageCalibration[key];
        }
      });
    }

    if (Object.keys(updateDoc).length > 0) {
      options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(updateDoc),
      };
      requestsList.push(
        fetchPostAuthSafe(
          '/settings/updateCustomCalibration',
          options,
          userSettings.username,
          userSettings.databaseName
        )
      );
    }

    // Custom Calibration - Gps
    updateDoc = {};

    const newGpsCalibration = {
      rdpEpsilon: parseFloat(formValues.rdpEpsilon),
    };

    if (Object.prototype.hasOwnProperty.call(customerSettings.customCalibration, 'gps')) {
      Object.keys(newGpsCalibration).forEach((key) => {
        if (customerSettings.customCalibration.gps[key] != newGpsCalibration[key]) {
          if (typeof updateDoc.gps === 'undefined') {
            updateDoc.gps = {};
          }
          updateDoc.gps[key] = newGpsCalibration[key];
        }
      });
    }

    if (Object.keys(updateDoc).length > 0) {
      options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(updateDoc),
      };
      requestsList.push(
        fetchPostAuthSafe(
          '/settings/updateCustomCalibration',
          options,
          userSettings.username,
          userSettings.databaseName
        )
      );
    }

    // Custom Calibration - Rei
    updateDoc = {};

    const newReiCalibration = {
      timeWindowCoverageDays: parseFloat(formValues.timeWindowCoverageDays),
      coverageCompletePercentThreshold: parseFloat(formValues.coverageCompletePercentThreshold),
    };

    if (Object.prototype.hasOwnProperty.call(customerSettings.customCalibration, 'rei')) {
      Object.keys(newReiCalibration).forEach((key) => {
        if (customerSettings.customCalibration.rei[key] != newReiCalibration[key]) {
          if (typeof updateDoc.rei === 'undefined') {
            updateDoc.rei = {};
          }
          updateDoc.rei[key] = newReiCalibration[key];
        }
      });
    }

    if (Object.keys(updateDoc).length > 0) {
      options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(updateDoc),
      };
      requestsList.push(
        fetchPostAuthSafe(
          '/settings/updateCustomCalibration',
          options,
          userSettings.username,
          userSettings.databaseName
        )
      );
    }

    // Custom Calibration - Task Cycle
    updateDoc = {};

    const newTaskCycleCalibration = {
      taskTimeWindowCoverageCompleteDays: parseFloat(formValues.taskTimeWindowCoverageCompleteDays),
      taskCoverageCompletePercentThreshold: parseFloat(formValues.taskCoverageCompletePercentThreshold),
    };

    if (Object.prototype.hasOwnProperty.call(customerSettings.customCalibration, 'taskCycle')) {
      Object.keys(newTaskCycleCalibration).forEach((key) => {
        if (customerSettings.customCalibration.taskCycle[key] != newTaskCycleCalibration[key]) {
          if (typeof updateDoc.taskCycle === 'undefined') {
            updateDoc.taskCycle = {};
          }
          updateDoc.taskCycle[key] = newTaskCycleCalibration[key];
        }
      });
    }

    if (Object.keys(updateDoc).length > 0) {
      options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(updateDoc),
      };
      requestsList.push(
        fetchPostAuthSafe(
          '/settings/updateCustomCalibration',
          options,
          userSettings.username,
          userSettings.databaseName
        )
      );
    }

    // Landing
    updateDoc = {};
    const landingBooleansAndStrings = ['landingPageEnabled', 'scanAutoStartEnabled'];
    const newLandingPageRedirects = {
      cabview: formValues.landingPageRedirectCabview,
      cropview: formValues.landingPageRedirectCropview,
      shopview: formValues.landingPageRedirectShopview,
      inspection: formValues.landingPageRedirectInspection,
      zoneInfo: formValues.landingPageRedirectZoneInfo,
    };

    for (let i = 0; i < landingBooleansAndStrings.length; i++) {
      if (customerSettings.landing[landingBooleansAndStrings[i]] != formValues[landingBooleansAndStrings[i]]) {
        updateDoc[landingBooleansAndStrings[i]] = formValues[landingBooleansAndStrings[i]];
      }
    }
    Object.keys(customerSettings.landing.landingPageRedirects).forEach((key) => {
      if (customerSettings.landing.landingPageRedirects[key] != newLandingPageRedirects[key]) {
        if (typeof updateDoc.landingPageRedirects === 'undefined') {
          updateDoc.landingPageRedirects = {};
        }

        updateDoc.landingPageRedirects[key] = newLandingPageRedirects[key];
      }
    });

    if (Object.keys(updateDoc).length > 0) {
      options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(updateDoc),
      };
      requestsList.push(
        fetchPostAuthSafe('/settings/updateLandingControl', options, userSettings.username, userSettings.databaseName)
      );
    }

    // Dashboard
    updateDoc = {};
    const dashboardBooleansAndStrings = [
      'kpiEfficiencyEnabled',
      'kpiTasksLoggedEnabled',
      'kpiMissingTasksEnabled',
      'kpiServiceCostsEnabled',
      'kpiServiceCompletionEnabled',
      'kpiInspectionRatesEnabled',
      'kpiMachineUsageEnabled',
      'kpiIdlingEnabled',
      'kpiOperationalEfficiencyEnabled',
      'kpiIdleInstallStatusWarningDisplay',
      'kpiEfficiencyHoverDetailsEnabled',
      'kpiIdleHoverDetailsEnabled',
      'efficiencyDollarsCalculatorEnabled',
    ];

    for (let i = 0; i < dashboardBooleansAndStrings.length; i++) {
      if (customerSettings.dashboard[dashboardBooleansAndStrings[i]] != formValues[dashboardBooleansAndStrings[i]]) {
        updateDoc[dashboardBooleansAndStrings[i]] = formValues[dashboardBooleansAndStrings[i]];
      }
    }

    if (Object.keys(updateDoc).length > 0) {
      options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(updateDoc),
      };
      requestsList.push(
        fetchPostAuthSafe('/settings/updateDashboardControl', options, userSettings.username, userSettings.databaseName)
      );
    }

    // Cabview
    updateDoc = {};
    const cabviewBooleansAndStrings = ['initPlotLineDisplayOn'];

    for (let i = 0; i < cabviewBooleansAndStrings.length; i++) {
      if (customerSettings.cabview[cabviewBooleansAndStrings[i]] != formValues[cabviewBooleansAndStrings[i]]) {
        updateDoc[cabviewBooleansAndStrings[i]] = formValues[cabviewBooleansAndStrings[i]];
      }
    }

    if (Object.keys(updateDoc).length > 0) {
      options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(updateDoc),
      };
      requestsList.push(
        fetchPostAuthSafe('/settings/updateCabviewControl', options, userSettings.username, userSettings.databaseName)
      );
    }

    // Cropview
    updateDoc = {};
    const cropviewBooleansAndStrings = ['vehicleAverageSpeedEnabled', 'enableTaskCycleTable'];
    const cropviewNums = ['taskCycleUpcomingWindowDays'];

    for (let i = 0; i < cropviewBooleansAndStrings.length; i++) {
      if (customerSettings.cropview[cropviewBooleansAndStrings[i]] != formValues[cropviewBooleansAndStrings[i]]) {
        updateDoc[cropviewBooleansAndStrings[i]] = formValues[cropviewBooleansAndStrings[i]];
      }
    }

    for (let i = 0; i < cropviewNums.length; i++) {
      let parsedNum = parseFloat(formValues[cropviewNums[i]]);
      if (isNaN(parsedNum)) {
        parsedNum = '';
      }
      if (customerSettings.cropview[cropviewNums[i]] != parsedNum) {
        updateDoc[cropviewNums[i]] = parsedNum;
      }
    }

    if (Object.keys(updateDoc).length > 0) {
      options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(updateDoc),
      };
      requestsList.push(
        fetchPostAuthSafe('/settings/updateCropviewControl', options, userSettings.username, userSettings.databaseName)
      );
    }

    // Shopview
    updateDoc = {};
    const shopviewBooleansAndStrings = [
      'diagnosticsTabEnabled',
      'nonGoDeviceVehiclesEnabled',
      'futureEntryWarningDisabled',
      'useCurrentEnabled',
      'newServiceMultiVehicleAdminOnly',
      'addHistoryMultiServiceAdminOnly',
    ];
    const shopviewNums = ['upcomingEngHrThresh'];
    const shopviewStringArrays = ['vehicleCreationNotificationEmails'];

    for (let i = 0; i < shopviewBooleansAndStrings.length; i++) {
      if (customerSettings.shopview[shopviewBooleansAndStrings[i]] != formValues[shopviewBooleansAndStrings[i]]) {
        updateDoc[shopviewBooleansAndStrings[i]] = formValues[shopviewBooleansAndStrings[i]];
      }
    }

    for (let i = 0; i < shopviewNums.length; i++) {
      const parsedNum = parseFloat(formValues[shopviewNums[i]]);
      if (customerSettings.shopview[shopviewNums[i]] != parsedNum) {
        updateDoc[shopviewNums[i]] = parsedNum;
      }
    }

    for (let i = 0; i < shopviewStringArrays.length; i++) {
      if (new Set(customerSettings.shopview[shopviewStringArrays[i]]) != new Set(formValues[shopviewStringArrays[i]])) {
        updateDoc[shopviewStringArrays[i]] = formValues[shopviewStringArrays[i]];
      }
    }

    // Since we display units for odo, if someone changes both the units and the od in the same submit we will assume they are entering in the original units
    const parsedUpcomingOdo = parseFloat(unitsSubmitConversion(parseFloat(formValues['upcomingOdoThresh']), units));
    if (customerSettings.shopview['upcomingOdoThresh'] != parsedUpcomingOdo) {
      updateDoc['upcomingOdoThresh'] = parsedUpcomingOdo;
    }

    if (Object.keys(updateDoc).length > 0) {
      options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(updateDoc),
      };
      requestsList.push(
        fetchPostAuthSafe('/settings/updateShopviewControl', options, userSettings.username, userSettings.databaseName)
      );
    }

    // Diagnostics
    updateDoc = {};
    const diagnosticsKeys = ['diagnosticsFilterCodesGo', 'diagnosticsFilterCodesSpn', 'diagnosticsEnableCodesFmi'];

    for (let i = 0; i < diagnosticsKeys.length; i++) {
      if (customerSettings.diagnostics[diagnosticsKeys[i]] != formValues[diagnosticsKeys[i]]) {
        const codesList = formValues[diagnosticsKeys[i]];
        let allCodesValid = true;
        const codesNums = codesList.map((str) => {
          const int = parseInt(str);
          if (!isNaN(int)) {
            return int;
          } else {
            console.error('Code invalid in: ', diagnosticsKeys[i], '| Code: ', str);
            allCodesValid = false;
          }
        });
        if (allCodesValid) {
          codesNums.sort((a, b) => {
            return a - b;
          });
          updateDoc[diagnosticsKeys[i]] = codesNums;
        }
      }
    }

    if (Object.keys(updateDoc).length > 0) {
      options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(updateDoc),
      };
      requestsList.push(
        fetchPostAuthSafe(
          '/settings/updateDiagnosticsControl',
          options,
          userSettings.username,
          userSettings.databaseName
        )
      );
    }

    // Inspection
    updateDoc = {};
    const inspectionBooleansAndStrings = [
      'inspectionReportAllItemsComplete',
      'inspectionReportCommentAllFailures',
      'inspectionReportNameInput',
      'inspectionFormWideModeEnabled',
      'equipmentShowMostRecentLocation',
      'notifyUseWithoutInspection',
    ];
    const inspectionStringArrays = ['inspectionReportFailureEmails', 'notificationEmailsUseWithoutInspection'];

    for (let i = 0; i < inspectionBooleansAndStrings.length; i++) {
      if (customerSettings.inspection[inspectionBooleansAndStrings[i]] != formValues[inspectionBooleansAndStrings[i]]) {
        updateDoc[inspectionBooleansAndStrings[i]] = formValues[inspectionBooleansAndStrings[i]];
      }
    }
    for (let i = 0; i < inspectionStringArrays.length; i++) {
      if (!isEqual(customerSettings.inspection[inspectionStringArrays[i]], formValues[inspectionStringArrays[i]])) {
        updateDoc[inspectionStringArrays[i]] = formValues[inspectionStringArrays[i]];
      }
    }

    if (Object.keys(updateDoc).length > 0) {
      options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(updateDoc),
      };
      requestsList.push(
        fetchPostAuthSafe(
          '/settings/updateInspectionControl',
          options,
          userSettings.username,
          userSettings.databaseName
        )
      );
    }

    // Reports
    updateDoc = {};

    const reportsStringArrays = ['reportsGlobalBccEmails'];

    for (let i = 0; i < reportsStringArrays.length; i++) {
      if (!isEqual(customerSettings.reports[reportsStringArrays[i]], formValues[reportsStringArrays[i]])) {
        updateDoc[reportsStringArrays[i]] = formValues[reportsStringArrays[i]];
      }
    }

    if (Object.keys(updateDoc).length > 0) {
      options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(updateDoc),
      };
      requestsList.push(
        fetchPostAuthSafe('/settings/updateReportsSettings', options, userSettings.username, userSettings.databaseName)
      );
    }

    // Make all calls
    const responses = await Promise.all(requestsList);
    const responsesJsons = responses.map((response) => {
      return response.json();
    });
    const results = await Promise.all(responsesJsons);
    for (let i = 0; i < results.length; i++) {
      if (results[i].status == 401) {
        navigate('/error', {state: {errorMsg: 'Unauthorized Access or Action Detected, Please try again'}});
        break;
      } else if (results[i].errorMsg) {
        navigate('/error', {state: {errorMsg: results[i].errorMsg}});
        break;
      }
    }
    await dispatch(checkAuthentication());
    await dispatch(getCustomerAndUserInfo());
    await dispatch(getSettingsData());
  }

  function generateManagementAccessSettings() {
    const access = userSettings.role == 'ic_admin';
    const settingsEdit = [
      {label: 'Team Management', setting: 'teamManagement'},
      {label: 'Vehicle Attributes Management', setting: 'vehicleAttributesManagement'},
      {label: 'Vehicle Status Management', setting: 'vehicleStatusManagement'},
      {label: 'Vehicle Features Management', setting: 'vehicleFeaturesManagement'},
      {label: 'Task Management', setting: 'taskManagement'},
      {label: 'Implement Management', setting: 'implementManagement'},
      {label: 'Device Management', setting: 'deviceManagement'},
      {label: 'Label Management', setting: 'labelManagement'},
      {label: 'Bluetooth Management', setting: 'bluetoothManagement'},
      {label: 'General Management', setting: 'generalManagement'},
      {label: 'Custom Calibration Management', setting: 'customCalibrationManagement'},
      {label: 'Landing Management', setting: 'landingManagement'},
      {label: 'CabView Management', setting: 'cabviewManagement'},
      {label: 'CropView Management', setting: 'cropviewManagement'},
      {label: 'ShopView Management', setting: 'shopviewManagement'},
      {label: 'Inspection Management', setting: 'inspectionManagement'},
      {label: 'Diagnostics Management', setting: 'diagnosticsManagement'},
      {label: 'Reports Management', setting: 'reportsManagement'},
      {label: 'StatsView Targets Management', setting: 'dashboardManagement'},
      {label: 'StatsView Scorecard Management', setting: 'dashboardScorecardManagement'},
      {label: 'MapView Row Spacing Management', setting: 'zoneInfoRowSpacingManagement'},
    ];
    return (
      <React.Fragment>
        {access && (
          <CollapsableSection
            title='Management Access'
            collapseOpen={collapseOpen}
            setCollapseOpen={setCollapseOpen}
            section='management'
          >
            <Table size='small' aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Edit</TableCell>
                  <TableCell>View</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {settingsEdit.map((setting, idx) => {
                  const editSetting = setting.setting;
                  const viewSetting = setting.setting + 'View';
                  return (
                    <TableRow key={idx}>
                      <TableCell>{setting.label}</TableCell>
                      <TableCell>
                        <Select
                          value={formValues[editSetting]}
                          name={editSetting}
                          size='small'
                          fullWidth
                          onChange={(e) => {
                            // Change view setting if edit setting is lower level
                            if (accessLevels[e.target.value] < accessLevels[formValues[viewSetting]]) {
                              // Create mock event to pass to actual event handler
                              const mockEvent = {
                                target: {
                                  name: viewSetting,
                                  value: e.target.value,
                                },
                              };
                              handleChange(mockEvent);
                            }
                            handleChange(e);
                          }}
                        >
                          <MenuItem value={'user'}>User</MenuItem>
                          <MenuItem value={'customer_admin'}>Admin</MenuItem>
                          <MenuItem value={'ic_admin'}>IC Admin</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell>
                        <Select
                          value={formValues[viewSetting]}
                          name={viewSetting}
                          size='small'
                          fullWidth
                          onChange={handleChange}
                        >
                          <MenuItem value={'user'}>User</MenuItem>
                          {accessLevels[formValues[editSetting]] >= accessLevels['customer_admin'] && (
                            <MenuItem value={'customer_admin'}>Admin</MenuItem>
                          )}
                          {accessLevels[formValues[editSetting]] >= accessLevels['ic_admin'] && (
                            <MenuItem value={'ic_admin'}>IC Admin</MenuItem>
                          )}
                        </Select>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </CollapsableSection>
        )}
      </React.Fragment>
    );
  }

  function generateProductAccessSettings() {
    const access = userSettings.role == 'ic_admin';
    return (
      <React.Fragment>
        {access && (
          <CollapsableSection
            title='Product Access'
            collapseOpen={collapseOpen}
            setCollapseOpen={setCollapseOpen}
            section='product'
          >
            <Table size='small' aria-label='simple table'>
              <TableBody>
                <SettingsSwitch
                  label='CabView Enabled'
                  setting='cabviewEnabled'
                  formValues={formValues}
                  handleChange={handleChange}
                />
                <SettingsSwitch
                  label='CropView Enabled'
                  setting='cropviewEnabled'
                  formValues={formValues}
                  handleChange={handleChange}
                />
                <SettingsSwitch
                  label='ShopView Enabled'
                  setting='shopviewEnabled'
                  formValues={formValues}
                  handleChange={handleChange}
                />
                <SettingsSwitch
                  label='Inspection Enabled'
                  setting='inspectionEnabled'
                  formValues={formValues}
                  handleChange={handleChange}
                />
                <SettingsSwitch
                  label='MapView Enabled'
                  setting='zoneInfoEnabled'
                  formValues={formValues}
                  handleChange={handleChange}
                />
                <SettingsSwitch
                  label='StatsView Enabled'
                  setting='dashboardEnabled'
                  formValues={formValues}
                  handleChange={handleChange}
                />
              </TableBody>
            </Table>
          </CollapsableSection>
        )}
      </React.Fragment>
    );
  }

  function generateGeneralSettings() {
    const access = userSettings.roleViewAccess['generalManagement'];
    const viewOnly = !userSettings.roleAccess['generalManagement'];
    return (
      <React.Fragment>
        {access && (
          <CollapsableSection
            title='General'
            collapseOpen={collapseOpen}
            setCollapseOpen={setCollapseOpen}
            section='general'
            viewOnly={viewOnly}
          >
            <Table size='small' aria-label='simple table'>
              <TableBody sx={{width: '100%'}}>
                <SettingsSwitch
                  label='Geofences Provided'
                  setting='geoFencesProvided'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='Server'
                  setting='server'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='Pipedrive Organization Id'
                  setting='pipedriveOrganizationId'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='Time Zone'
                  setting='timeZone'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='Hour Offset'
                  setting='hourOffSet'
                  type='number'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='Minute Offset'
                  setting='minuteOffSet'
                  type='number'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsSwitch
                  label='Out of Field Time'
                  setting='outOfFieldTime'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsSwitch
                  label='Idle Time'
                  setting='idleTime'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsSwitch
                  label='Row Pass Statistics Enabled'
                  setting='rowPassStatisticsEnabled'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='Idle Time Trigger Threshold'
                  setting='idleTimeTriggerThresh'
                  type='number'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsSwitch
                  label='Acreage Capping for Specific Tasks'
                  setting='enableTaskAcreageCapping'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsSwitch
                  label='Cycle for Specific Tasks'
                  setting='enableTaskCycle'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsSwitch
                  label='Reentry Interval for Specific Tasks'
                  setting='enableTaskRei'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsSwitch
                  label='Reentry Interval Map Display Enabled'
                  setting='enableReiDisplay'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsSwitch
                  label='Rec Application Enabled'
                  setting='enableRecApplication'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsSwitch
                  label='Bluetooth Tags Enabled'
                  setting='enableBluetoothTags'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsSwitch
                  label='Engine Hours Use Ign Eng Hr Estimation'
                  setting='engineHoursUseIgnEngHrEstimation'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsSwitch
                  label='Zone Entry and Exit Time Enabled'
                  setting='zoneEntryAndExitTimeEnabled'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsSwitch
                  label='Device Management Enabled'
                  setting='deviceManagementEnabled'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsSelect
                  label='Units'
                  setting='units'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                  options={[
                    {value: 'metric', label: 'Metric'},
                    {value: 'imperial', label: 'Imperial'},
                  ]}
                />
                <SettingsText
                  label='Gps Processing Rate (sec)'
                  setting='gpsProcessingRateSec'
                  type='number'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='TripTask Processing Rate (sec)'
                  setting='tripTaskProcessingRateSec'
                  type='number'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                {userSettings.role == 'ic_admin' && (
                  <SettingsSelect
                    label='Vehicle Status Visibility'
                    setting='vehicleStatusVisibility'
                    formValues={formValues}
                    handleChange={handleChange}
                    disabled={viewOnly}
                    options={[
                      {value: 'user', label: 'User'},
                      {value: 'customer_admin', label: 'Admin'},
                      {value: 'ic_admin', label: 'IC Admin'},
                    ]}
                  />
                )}
                <SettingsChipInput
                  label='Permanent Task/Implement Expiry Notification Emails'
                  setting='expirePermanentTaskImplementEmailList'
                  formValues={formValues}
                  handleChange={handleChange}
                  inputType='email'
                  disabled={viewOnly}
                />
                <SettingsChipInput
                  label='User Creation Notification Emails'
                  setting='userCreationNotificationEmails'
                  formValues={formValues}
                  handleChange={handleChange}
                  inputType='email'
                  disabled={viewOnly}
                />
              </TableBody>
            </Table>
          </CollapsableSection>
        )}
      </React.Fragment>
    );
  }

  function generateCustomCalibrationSettings() {
    const access = userSettings.roleViewAccess['customCalibrationManagement'];
    const viewOnly = !userSettings.roleAccess['customCalibrationManagement'];

    return (
      <React.Fragment>
        {access && (
          <CollapsableSection
            title='Custom Calibration'
            collapseOpen={collapseOpen}
            setCollapseOpen={setCollapseOpen}
            section='calibration'
            viewOnly={viewOnly}
          >
            <Table size='small' aria-label='simple table'>
              <TableBody sx={{width: '100%'}}>
                <SettingsText
                  label='Coverage - Percent Filter'
                  setting='coveragePercentFilter'
                  type='number'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='Coverage - Area Min Filter'
                  setting='coverageAreaMinFilter'
                  type='number'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='Coverage - Duration Min Filter'
                  setting='coverageDurationMinFilter'
                  type='number'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='Coverage - Non Edge Passes Threshold'
                  setting='coverageNonEdgePassesThreshold'
                  type='number'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='Coverage - Edge Passes Inclusion Threshold'
                  setting='coverageEdgePassesInclusionThreshold'
                  type='number'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='Coverage - Row Pass Summary Min Duration (s)'
                  setting='coverageRowPassSummMinDurationSec'
                  type='number'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='Coverage - Row Pass Summary Average Speed (KPH)'
                  setting='coverageRowPassSummAveSpeedKph'
                  type='number'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='Coverage - Duration Min Filter for Rowpass'
                  setting='aggRowDurationThreshold'
                  type='number'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='Coverage - Min Non Buffer Zone Coverage Percent Per Day for Rowpass'
                  setting='rowpassMinTripNonBufferPercentPerDay'
                  type='number'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='Coverage - Min Buffer Zone Coverage Percent Per Day for Rowpass'
                  setting='rowpassMinTripBufferPercentPerDay'
                  type='number'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='Coverage - Min Non Buffer Zone Coverage Percent Per Day for Triptasks'
                  setting='tripTaskMinTripNonBufferPercentPerDay'
                  type='number'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='Coverage - Min Buffer Zone Coverage Percent Per Day for Triptasks'
                  setting='tripTaskMinTripBufferPercentPerDay'
                  type='number'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsSwitch
                  label='Row Pass - GPS Data Up Sampling'
                  setting='rowpassGpsDataUpSampling'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='Row Pass - Point to Line Distance Threshold'
                  setting='rowpassPointToLineDistanceThreshold'
                  type='number'
                  inputProps={{step: 0.001}}
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='Row Pass - Point to Polygon Distance Threshold'
                  setting='rowpassPointToPolygonDistanceThreshold'
                  type='number'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='Row Pass - Cross to Row Bearing Angle Threshold'
                  setting='rowpassCrossToRowBearingAngleThreshold'
                  type='number'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='Row Pass - Cross to Bearing Distance Minimum Threshold'
                  setting='rowpassCrossToBearingDistanceMinThreshold'
                  type='number'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='Row Pass - Edge Pass Avg Speed Threshold (KPH)'
                  setting='rowpassEdgePassAvgSpeedThresholdKph'
                  type='number'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='Row Pass - Resample Min. Distance (m)'
                  setting='rowpassResampleMinDistanceMeter'
                  type='number'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='Row Pass - CrossPass Max Speed Exclusion Threshold (KPH)'
                  setting='rowpassCrossPassMaxSpeedExclusionThreshKph'
                  type='number'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='Row Pass - Cross Pass Max Distance Exclusion Threshold (m)'
                  setting='rowpassCrosspassMaxDistExclusionThreshM'
                  type='number'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='TripTask - Turning Bearing Threshold (Rad)'
                  setting='tripTaskTurningBearingThreshRad'
                  type='number'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='TripTask - Turning Bearing Threshold (m)'
                  setting='tripTaskTurningBearingThreshM'
                  type='number'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='TripTask - Buffer Zone Percent Threshold (%)'
                  setting='bufferTripPercentThresh'
                  type='number'
                  inputProps={{step: 0.01}}
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='TripTask - Min Trip Duration Threshold (s)'
                  setting='minTripDuration'
                  type='number'
                  inputProps={{step: 1}}
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='TripTask - Max Trip Speed Threshold (m/min)'
                  setting='maxTripSpeed'
                  type='number'
                  inputProps={{step: 1}}
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='GPS - Ramer-Douglas-Peucker Epsilon (log scale: 0.00001 ~=1.11m)'
                  setting='rdpEpsilon'
                  type='number'
                  inputProps={{step: 0.00001}}
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='REI - Time Window for Coverage (days)'
                  setting='timeWindowCoverageDays'
                  type='number'
                  inputProps={{step: 1}}
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='REI - Coverage Complete Percent Threshold (%)'
                  setting='coverageCompletePercentThreshold'
                  type='number'
                  inputProps={{step: 1}}
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='TASK CYCLE - Time Window for Completing Task Coverage (days)'
                  setting='taskTimeWindowCoverageCompleteDays'
                  type='number'
                  inputProps={{step: 1}}
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='TASK CYCLE - Task Coverage Complete Percent Threshold (%)'
                  setting='taskCoverageCompletePercentThreshold'
                  type='number'
                  inputProps={{step: 1}}
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
              </TableBody>
            </Table>
          </CollapsableSection>
        )}
      </React.Fragment>
    );
  }

  function generateLandingSettings() {
    const access = userSettings.roleViewAccess['landingManagement'];
    const viewOnly = !userSettings.roleAccess['landingManagement'];
    return (
      <React.Fragment>
        {access && (
          <CollapsableSection
            title='Landing Page'
            collapseOpen={collapseOpen}
            setCollapseOpen={setCollapseOpen}
            section='landing'
            viewOnly={viewOnly}
          >
            <Table size='small' aria-label='simple table'>
              <TableBody>
                <SettingsSwitch
                  label='Landing Page Enabled'
                  setting='landingPageEnabled'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsSwitch
                  label='Scan Auto Start'
                  setting='scanAutoStartEnabled'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsSwitch
                  label='CabView Redirect Enabled'
                  setting='landingPageRedirectCabview'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsSwitch
                  label='CropView Redirect Enabled'
                  setting='landingPageRedirectCropview'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsSwitch
                  label='ShopView Redirect Enabled'
                  setting='landingPageRedirectShopview'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsSwitch
                  label='Inspection Redirect Enabled'
                  setting='landingPageRedirectInspection'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsSwitch
                  label='MapView Redirect Enabled'
                  setting='landingPageRedirectZoneInfo'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
              </TableBody>
            </Table>
          </CollapsableSection>
        )}
      </React.Fragment>
    );
  }

  function generateDashboardSettings() {
    const access = userSettings.roleViewAccess['dashboardManagement'];
    const viewOnly = !userSettings.roleAccess['dashboardManagement'];
    return (
      <React.Fragment>
        {access && (
          <CollapsableSection
            title='StatsView Settings'
            collapseOpen={collapseOpen}
            setCollapseOpen={setCollapseOpen}
            section='dashboard'
            viewOnly={viewOnly}
          >
            <React.Fragment>
              <Table size='small' aria-label='simple table'>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <div className='mt-2' style={{color: 'black', fontWeight: 600}}>
                        Farming
                        {viewOnly && (
                          <span>
                            <b> - View Only</b>
                          </span>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                  <SettingsSwitch
                    label='Efficiency Tab Enabled'
                    setting='kpiEfficiencyEnabled'
                    formValues={formValues}
                    handleChange={handleChange}
                    disabled={viewOnly}
                  />
                  <SettingsSwitch
                    label='Operational Efficiency Tab Enabled'
                    setting='kpiOperationalEfficiencyEnabled'
                    formValues={formValues}
                    handleChange={handleChange}
                    disabled={viewOnly}
                  />
                  <SettingsSwitch
                    label='Tasks Logged Tab Enabled'
                    setting='kpiTasksLoggedEnabled'
                    formValues={formValues}
                    handleChange={handleChange}
                    disabled={viewOnly}
                  />
                  <SettingsSwitch
                    label='Missing Tasks Tab Enabled'
                    setting='kpiMissingTasksEnabled'
                    formValues={formValues}
                    handleChange={handleChange}
                    disabled={viewOnly}
                  />
                  <SettingsSwitch
                    label='Efficiency Tab - Hover Show Acreage and Duration'
                    setting='kpiEfficiencyHoverDetailsEnabled'
                    formValues={formValues}
                    handleChange={handleChange}
                    disabled={viewOnly}
                  />
                </TableBody>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <div className='mt-2' style={{color: 'black', fontWeight: 600}}>
                        Machinery
                        {viewOnly && (
                          <span>
                            <b> - View Only</b>
                          </span>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                  <SettingsSwitch
                    label='Machine Usage Tab Enabled'
                    setting='kpiMachineUsageEnabled'
                    formValues={formValues}
                    handleChange={handleChange}
                    disabled={viewOnly}
                  />
                  <SettingsSwitch
                    label='Idling Tab Enabled'
                    setting='kpiIdlingEnabled'
                    formValues={formValues}
                    handleChange={handleChange}
                    disabled={viewOnly}
                  />
                  <SettingsSwitch
                    label='Idle Install Status Warning Enabled'
                    setting='kpiIdleInstallStatusWarningDisplay'
                    formValues={formValues}
                    handleChange={handleChange}
                    disabled={viewOnly}
                  />
                  <SettingsSwitch
                    label='Idle - Hover Show Total Idle Hours'
                    setting='kpiIdleHoverDetailsEnabled'
                    formValues={formValues}
                    handleChange={handleChange}
                    disabled={viewOnly}
                  />
                  <SettingsSwitch
                    label='Service Costs Tab Enabled'
                    setting='kpiServiceCostsEnabled'
                    formValues={formValues}
                    handleChange={handleChange}
                    disabled={viewOnly}
                  />
                  <SettingsSwitch
                    label='Service Completion Tab Enabled'
                    setting='kpiServiceCompletionEnabled'
                    formValues={formValues}
                    handleChange={handleChange}
                    disabled={viewOnly}
                  />
                  <SettingsSwitch
                    label='Inspection Rates Tab Enabled'
                    setting='kpiInspectionRatesEnabled'
                    formValues={formValues}
                    handleChange={handleChange}
                    disabled={viewOnly}
                  />
                  <SettingsSwitch
                    label='Efficiency Dollars Calculator Enabled'
                    setting='efficiencyDollarsCalculatorEnabled'
                    formValues={formValues}
                    handleChange={handleChange}
                    disabled={viewOnly}
                  />
                </TableBody>
              </Table>
            </React.Fragment>
          </CollapsableSection>
        )}
      </React.Fragment>
    );
  }

  function generateCabviewSettings() {
    const access = userSettings.roleViewAccess['cabviewManagement'];
    const viewOnly = !userSettings.roleAccess['cabviewManagement'];
    return (
      <React.Fragment>
        {access && (
          <CollapsableSection
            title='CabView Settings'
            collapseOpen={collapseOpen}
            setCollapseOpen={setCollapseOpen}
            section='cabview'
            viewOnly={viewOnly}
          >
            <Table size='small' aria-label='simple table'>
              <TableBody>
                <SettingsSwitch
                  label='Init Plot Line Display'
                  setting='initPlotLineDisplayOn'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
              </TableBody>
            </Table>
          </CollapsableSection>
        )}
      </React.Fragment>
    );
  }

  function generateCropviewSettings() {
    const access = userSettings.roleViewAccess['cropviewManagement'];
    const viewOnly = !userSettings.roleAccess['cropviewManagement'];
    return (
      <React.Fragment>
        {access && (
          <CollapsableSection
            title='CropView Settings'
            collapseOpen={collapseOpen}
            setCollapseOpen={setCollapseOpen}
            section='cropview'
            viewOnly={viewOnly}
          >
            <Table size='small' aria-label='simple table'>
              <TableBody>
                <SettingsSwitch
                  label='Vehicle Average Speed Enabled'
                  setting='vehicleAverageSpeedEnabled'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsSwitch
                  label='Task Cycle Table Enabled'
                  setting='enableTaskCycleTable'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='Task Cycle Upcoming Window Days'
                  setting='taskCycleUpcomingWindowDays'
                  type='number'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
              </TableBody>
            </Table>
          </CollapsableSection>
        )}
      </React.Fragment>
    );
  }

  function generateShopviewSettings() {
    const access = userSettings.roleViewAccess['shopviewManagement'];
    const viewOnly = !userSettings.roleAccess['shopviewManagement'];
    return (
      <React.Fragment>
        {access && (
          <CollapsableSection
            title='ShopView Settings'
            collapseOpen={collapseOpen}
            setCollapseOpen={setCollapseOpen}
            section='shopview'
            viewOnly={viewOnly}
          >
            <Table size='small' aria-label='simple table'>
              <TableBody>
                <SettingsSwitch
                  label='Non GoDevice Vehicles Enabled'
                  setting='nonGoDeviceVehiclesEnabled'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsSwitch
                  label='Shopview Diagnostics Display Enabled'
                  setting='diagnosticsTabEnabled'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsSwitch
                  label='Future Entry Warning Disabled'
                  setting='futureEntryWarningDisabled'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsSwitch
                  label='Use Current Enabled'
                  setting='useCurrentEnabled'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsSwitch
                  label='New Service - Multi Vehicle Admin Only'
                  setting='newServiceMultiVehicleAdminOnly'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsSwitch
                  label='Add History - Multi Service Admin Only'
                  setting='addHistoryMultiServiceAdminOnly'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label={`Upcoming Odo Threshold ${unitsSystem == 'metric' ? '(km)' : '(mi)'}`}
                  setting='upcomingOdoThresh'
                  type='number'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsText
                  label='Upcoming Engine Hour Threshold (hr)'
                  setting='upcomingEngHrThresh'
                  type='number'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsChipInput
                  label='Vehicle Creation Notification Emails'
                  setting='vehicleCreationNotificationEmails'
                  formValues={formValues}
                  handleChange={handleChange}
                  inputType='email'
                  disabled={viewOnly}
                />
              </TableBody>
            </Table>
          </CollapsableSection>
        )}
      </React.Fragment>
    );
  }

  function generateDiagnosticsSettings() {
    const access = userSettings.roleViewAccess['diagnosticsManagement'];
    const viewOnly = !userSettings.roleAccess['diagnosticsManagement'];
    return (
      <React.Fragment>
        {access && (
          <CollapsableSection
            title='Diagnostics Settings'
            collapseOpen={collapseOpen}
            setCollapseOpen={setCollapseOpen}
            section='diagnostics'
            viewOnly={viewOnly}
          >
            <Table size='small' aria-label='simple table'>
              <TableBody>
                <SettingsChipInput
                  label='Diagnostics Filter Codes GO'
                  setting='diagnosticsFilterCodesGo'
                  formValues={formValues}
                  handleChange={handleChange}
                  inputType='integer'
                  disabled={viewOnly}
                />
                <SettingsChipInput
                  label='Diagnostics Filter Codes SPN'
                  setting='diagnosticsFilterCodesSpn'
                  formValues={formValues}
                  handleChange={handleChange}
                  inputType='integer'
                  disabled={viewOnly}
                />
                <SettingsChipInput
                  label='Diagnostics Enable Codes FMI'
                  setting='diagnosticsEnableCodesFmi'
                  formValues={formValues}
                  handleChange={handleChange}
                  inputType='integer'
                  disabled={viewOnly}
                />
              </TableBody>
            </Table>
          </CollapsableSection>
        )}
      </React.Fragment>
    );
  }

  function generateInspectionSettings() {
    const access = userSettings.roleViewAccess['inspectionManagement'];
    const viewOnly = !userSettings.roleAccess['inspectionManagement'];
    return (
      <React.Fragment>
        {access && (
          <CollapsableSection
            title='Inspection Settings'
            collapseOpen={collapseOpen}
            setCollapseOpen={setCollapseOpen}
            section='inspection'
            viewOnly={viewOnly}
          >
            <Table size='small' aria-label='simple table'>
              <TableBody>
                <SettingsChipInput
                  label='Inspection Report Failure Emails'
                  setting='inspectionReportFailureEmails'
                  formValues={formValues}
                  handleChange={handleChange}
                  inputType='email'
                  disabled={viewOnly}
                />
                <SettingsSwitch
                  label='Inspection Report All Items Complete'
                  setting='inspectionReportAllItemsComplete'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsSwitch
                  label='Inspection Report Comment All Failures'
                  setting='inspectionReportCommentAllFailures'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsSwitch
                  label='Inspection Report Name Input'
                  setting='inspectionReportNameInput'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsSwitch
                  label='Inspection Report Show in Wide Mode'
                  setting='inspectionFormWideModeEnabled'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsSwitch
                  label='Notify Use Without Inspection'
                  setting='notifyUseWithoutInspection'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                />
                <SettingsSelect
                  label='Equipment Show Most Recent Location'
                  setting='equipmentShowMostRecentLocation'
                  formValues={formValues}
                  handleChange={handleChange}
                  disabled={viewOnly}
                  options={[
                    {value: 'None', label: 'None'},
                    {value: 'Block', label: 'Block'},
                    {value: 'Field', label: 'Field'},
                    {value: 'Region', label: 'Region'},
                  ]}
                />
                <SettingsChipInput
                  label='Use Without Insepection Notification Emails'
                  setting='notificationEmailsUseWithoutInspection'
                  formValues={formValues}
                  handleChange={handleChange}
                  inputType='email'
                  disabled={viewOnly}
                />
              </TableBody>
            </Table>
          </CollapsableSection>
        )}
      </React.Fragment>
    );
  }

  function generateReportsSettings() {
    const access = userSettings.roleViewAccess['reportsManagement'];
    const viewOnly = !userSettings.roleAccess['reportsManagement'];
    return (
      <React.Fragment>
        {access && (
          <CollapsableSection
            title='Reports Settings'
            collapseOpen={collapseOpen}
            setCollapseOpen={setCollapseOpen}
            section='reports'
            viewOnly={viewOnly}
          >
            <Table size='small' aria-label='simple table'>
              <TableBody>
                <SettingsChipInput
                  label='Global Bcc Email'
                  setting='reportsGlobalBccEmails'
                  formValues={formValues}
                  handleChange={handleChange}
                  inputType='email'
                  disabled={viewOnly}
                />
              </TableBody>
            </Table>
          </CollapsableSection>
        )}
      </React.Fragment>
    );
  }

  return (
    <div className='tab-wrapper'>
      <div className='mt-1 row mx-0 my-1'>
        {(userSettings.roleAccess['productAccessManagement'] ||
          userSettings.roleAccess['generalManagement'] ||
          userSettings.roleAccess['landingManagement'] ||
          userSettings.roleAccess['cabviewManagement'] ||
          userSettings.roleAccess['cropviewManagement'] ||
          userSettings.roleAccess['shopviewManagement'] ||
          userSettings.roleAccess['diagnosticsManagement'] ||
          userSettings.roleAccess['reportsManagement'] ||
          userSettings.roleAccess['inspectionManagement']) && (
          <React.Fragment>
            <Button variant='ic-button' color='secondary' title='Reset changes' onClick={resetFormValues}>
              Reset
            </Button>
            <Button
              variant='ic-button'
              color='primary'
              title='Submit changes'
              className='ml-1'
              onClick={handleSubmit}
              disabled={loading}
            >
              Submit
            </Button>
          </React.Fragment>
        )}
      </div>
      {loading ? (
        <CircularProgress className='mt-2 mx-auto d-block' size={80} />
      ) : (
        <React.Fragment>
          <div className='tab-wrapper overflow-auto'>
            {/* MANAGEMENT ACCESS */}
            {generateManagementAccessSettings()}

            {/* PRODUCT ACCESS */}
            {generateProductAccessSettings()}

            {/* GENERAL */}
            {generateGeneralSettings()}

            {/* LANDING */}
            {generateLandingSettings()}

            {/* CABVIEW */}
            {generateCabviewSettings()}

            {/* CROPVIEW */}
            {generateCropviewSettings()}

            {/* SHOPVIEW */}
            {generateShopviewSettings()}

            {/* REPORTS */}
            {generateReportsSettings()}

            {/* Inspections */}
            {generateInspectionSettings()}

            {/* DIAGNOSTICS */}
            {generateDiagnosticsSettings()}

            {/* DASHBOARD */}
            {generateDashboardSettings()}

            {/* Custom Calibration */}
            {generateCustomCalibrationSettings()}
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export {ProductTab};
