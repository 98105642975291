import React from 'react';
import {intRegex, emailRegex} from '../app/utils';

// MUI
import {MuiChipsInput} from 'mui-chips-input';
import Chip from '@mui/material/Chip';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  Collapse,
  CircularProgress,
  TableRow,
  TableCell,
  Switch,
  Select,
  MenuItem,
  TextField,
  Autocomplete,
  Tooltip,
} from '@mui/material';

export function CollapsableSection(props) {
  const open = props.collapseOpen[props.section];
  return (
    <div>
      <h5 className='mt-2' style={{color: 'black'}}>
        {props.title}
        {props.viewOnly && <span style={{fontWeight: 'bold'}}> - View Only</span>}
        <span
          className='ml-2 hoverPls'
          onClick={() => {
            props.setCollapseOpen((values) => {
              return {...values, [props.section]: !props.collapseOpen[props.section]};
            });
          }}
        >
          {open ? <ExpandLess /> : <ExpandMore />}
        </span>
      </h5>
      <Collapse in={open} timeout='auto' unmountOnExit>
        {props.children}
      </Collapse>
    </div>
  );
}

export function ModalSubmitting(props) {
  return (
    <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
      <CircularProgress className='mt-4 mx-auto d-block' size={80} />
      <span className='mt-3'>Submitting ...</span>
    </div>
  );
}

export function ViewOnlyText(props) {
  return (
    <h5 className='mt-2' style={{color: 'blue'}}>
      <i>View Only</i>
    </h5>
  );
}

export function SettingsSwitch(props) {
  return (
    <TableRow>
      <TableCell>{props.label}</TableCell>
      <TableCell>
        <Switch
          disabled={props.disabled}
          name={props.setting}
          checked={props.formValues[props.setting]}
          onChange={props.handleChange}
        />
      </TableCell>
    </TableRow>
  );
}

export function TargetText(props) {
  let value = '';
  try {
    value = props.formValues[props.outerTarget][props.innerTarget][props.targetName];
  } catch (error) {
    value = '';
  }

  return (
    <TableRow>
      {props.showLabel && <TableCell>{props.label}</TableCell>}
      <TableCell>
        <TextField
          disabled={props.disabled}
          size='small'
          fullWidth
          type={props.type ?? 'text'}
          placeholder={props.placeholder}
          InputProps={{inputProps: props.inputProps}}
          name={props.innerTarget}
          value={value}
          onChange={props.handleChange}
        />
      </TableCell>
    </TableRow>
  );
}

export function SettingsText(props) {
  return (
    <TableRow>
      <TableCell>{props.label}</TableCell>
      <TableCell>
        <TextField
          disabled={props.disabled}
          size='small'
          fullWidth
          type={props.type ?? 'text'}
          placeholder={props.placeholder}
          InputProps={{inputProps: props.inputProps}}
          name={props.setting}
          value={props.formValues[props.setting]}
          onChange={props.handleChange}
        />
      </TableCell>
    </TableRow>
  );
}

export function SettingsTextAndToggle(props) {
  return (
    <TableRow>
      <TableCell>{props.label}</TableCell>
      <TableCell>
        <Switch
          disabled={props.disabled}
          name={props.toggleSetting}
          checked={props.formValues[props.toggleSetting]}
          onChange={props.handleChange}
        />
        <TextField
          disabled={props.disabled || !props.formValues[props.toggleSetting]}
          size='small'
          type={props.type ?? 'text'}
          placeholder={props.placeholder}
          InputProps={{inputProps: props.inputProps}}
          name={props.setting}
          value={props.formValues[props.setting]}
          onChange={props.handleChange}
        />
      </TableCell>
    </TableRow>
  );
}

export function SettingsSelect(props) {
  return (
    <TableRow>
      <TableCell>{props.label}</TableCell>
      <TableCell>
        <Select
          multiple={props.multiple}
          disabled={props.disabled}
          size='small'
          fullWidth
          name={props.setting}
          value={props.formValues[props.setting]}
          onChange={props.handleChange}
        >
          {props.options.map((option, idx) => {
            return (
              <MenuItem key={idx} value={option.value}>
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
      </TableCell>
    </TableRow>
  );
}

export function SettingsSearchSelect(props) {
  return (
    <TableRow>
      <TableCell>{props.label}</TableCell>
      <TableCell>
        <Autocomplete
          multiple={props.multiple}
          disabled={props.disabled}
          size='small'
          fullWidth
          limitTags={2}
          disableCloseOnSelect={props.multiple}
          name={props.setting}
          value={props.formValues[props.setting]}
          onChange={(event, value) => {
            // Autocomplete will return option object, but initial values will be return just the value
            let valueAdjusted = '';
            if (Array.isArray(value)) {
              valueAdjusted = value.map((option) => {
                if (typeof option == 'object') {
                  return option.value;
                } else {
                  return option;
                }
              });
            } else if (value !== null) {
              if (typeof value == 'object') {
                valueAdjusted = value.value;
              } else {
                valueAdjusted = value;
              }
            }

            // Create mock event to pass to actual event handler
            const mockEvent = {
              target: {
                name: props.setting,
                value: valueAdjusted,
              },
            };
            props.handleChange(mockEvent);
          }}
          options={props.options}
          getOptionLabel={(option) => {
            if (typeof option == 'object') {
              return option.label;
            } else {
              const optionObj = props.options.find((opt) => {
                return opt.value == option;
              });
              if (optionObj) {
                return optionObj.label;
              } else {
                return '';
              }
            }
          }}
          renderInput={(params) => {
            return <TextField {...params} placeholder='Search...' error={props.error} helperText={props.helperText} />;
          }}
          isOptionEqualToValue={(option, value) => {
            return option.value === value;
          }}
        />
      </TableCell>
    </TableRow>
  );
}

export function SettingsChipInput(props) {
  // Variables for text inputType
  let type = 'text';
  let varType = 'string';
  let regex = null;
  let errorMsg = '';

  if (props.inputType == 'integer') {
    // Variables for integer inputType
    type = 'number';
    varType = 'int';
    regex = intRegex;
    errorMsg = 'The value must be an integer';
  } else if (props.inputType == 'email') {
    // Variables for email inputType
    type = 'email';
    varType = 'string';
    regex = emailRegex;
    errorMsg = 'Please enter an email address';
  }

  return (
    <TableRow>
      <TableCell>{props.label}</TableCell>
      <TableCell>
        <MuiChipsInput
          disabled={props.disabled}
          size='small'
          type={type}
          fullWidth
          hideClearAll
          disableDeleteOnBackspace
          name={props.setting}
          value={props.formValues[props.setting]}
          onChange={(value) => {
            let tempValues = [...value];
            if (varType == 'int') {
              tempValues = tempValues.map((val) => {
                return parseInt(val);
              });
            }

            // Create mock event to pass to actual event handler
            const mockEvent = {
              target: {
                name: props.setting,
                value: tempValues,
              },
            };
            props.handleChange(mockEvent);
          }}
          validate={(newValue) => {
            if (regex === null) {
              return true;
            }
            return {
              isError: !regex.test(newValue),
              textError: errorMsg,
            };
          }}
        />
      </TableCell>
    </TableRow>
  );
}

export function GeneralSearchSelect(props) {
  return (
    <Autocomplete
      multiple={props.multiple}
      disabled={props.disabled}
      size='small'
      fullWidth
      limitTags={2}
      disableCloseOnSelect={props.multiple}
      name={props.name}
      value={props.value}
      options={props.options}
      getOptionDisabled={(option) => {
        return option.disabled;
      }}
      onChange={(event, value) => {
        // Autocomplete will return option object, but initial values will be return just the value
        let valueAdjusted = '';
        if (Array.isArray(value)) {
          valueAdjusted = value.map((option) => {
            if (typeof option == 'object') {
              return option.value;
            } else {
              return option;
            }
          });
        } else if (value !== null) {
          if (typeof value == 'object') {
            valueAdjusted = value.value;
          } else {
            valueAdjusted = value;
          }
        }

        // Create mock event to pass to actual event handler
        const mockEvent = {
          target: {
            name: props.name,
            value: valueAdjusted,
          },
        };
        props.handleChange(mockEvent);
      }}
      getOptionLabel={(option) => {
        if (typeof option == 'object') {
          return option.label;
        } else {
          const optionObj = props.options.find((opt) => {
            return opt.value == option;
          });
          if (optionObj) {
            return optionObj.label;
          } else {
            return '';
          }
        }
      }}
      renderInput={(params) => {
        return <TextField {...params} placeholder='Search...' error={props.error} helperText={props.helperText} />;
      }}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((value, index) => {
          const {key, ...tagProps} = getTagProps({index});
          const targetOption = props.options.find((option) => {
            return option.value == value;
          });
          if (targetOption) {
            return (
              <Chip size='small' key={key} label={targetOption.label} {...tagProps} disabled={targetOption.disabled} />
            );
          } else {
            return null;
          }
        });
      }}
      isOptionEqualToValue={(option, value) => {
        return option.value === value;
      }}
    />
  );
}

export function InlineTooltip(props) {
  return (
    <Tooltip
      arrow
      placement='bottom-start'
      PopperProps={{
        disablePortal: true,
        popperOptions: {
          positionFixed: false,
          modifiers: {
            preventOverflow: {
              enabled: true,
            },
          },
        },
      }}
      sx={{
        maxWidth: '300px',
        display: 'inline-block',
        padding: 0,
      }}
      title={props.title}
    >
      <button className='btn btn-sm d-inline-flex m-0 p-1'>{props.icon}</button>
    </Tooltip>
  );
}
