import {createSlice} from '@reduxjs/toolkit';

const zoneInfoSlice = createSlice({
  name: 'zoneinfo',
  initialState: {
    loading: true,
    zonesDicts: {
      blocks: {},
      fields: {},
      regions: {},
    },
    geoFencesDict: {},
    displayedTable: 'block',
    cropTypesDict: {}, // Store crop data as {primaryClass: [subClass...]}
    cropTypesDropdownOptions: [], // Store formated crop type edit dropdown options
    editZoneInfo: false,
    editZoneInfoValues: {},
    sortMethod: 'name',
    statusFilter: 'active',
    mapView: 1,
    zoneZoom: '',
    todayOnly: false,
  },
  reducers: {
    updateLoading(state, action) {
      state.loading = action.payload;
    },
    updateZoneInfoData(state, action) {
      const allZoneData = action.payload;

      state.zonesDicts = {
        blocks: allZoneData.blocks,
        fields: allZoneData.fields,
        regions: allZoneData.regions
      };
      state.geoFencesDict = allZoneData.geoFences;
    },
    updateDisplayedTable(state, action) {
      state.displayedTable = action.payload;
    },
    updateCropTypesDict(state, action) {
      state.cropTypesDict = action.payload;
    },
    updateCropTypesDropdownOptions(state, action) {
      state.cropTypesDropdownOptions = action.payload;
    },
    updateEditZoneInfo(state, action) {
      const editZoneInfoBool = action.payload;

      state.editZoneInfo = editZoneInfoBool;
      if (!editZoneInfoBool) {
        state.editZoneInfoValues = {};
      }
    },
    updateEditZoneInfoValues(state, action) {
      state.editZoneInfoValues = action.payload;
    },
    updateDisplayedColumn(state, action) {
      state.displayedColumn = action.payload;
    },
    updateSortMethod(state, action) {
      state.sortMethod = action.payload;
    },
    updateStatusFilter(state, action) {      
      state.statusFilter = action.payload;
    },
    updateMapView(state, action) {
      // 0: Only table, 1: Table and map, 2: Only map
      const mapSetting = action.payload;
      if (mapSetting == undefined) {
        state.mapView = (state.mapView + 1) % 3;
      } else {
        state.mapView = mapSetting;
      }
    },
    updateZoneZoom(state, action) {
      state.zoneZoom = action.payload;
    },
  },
});

export const {
  updateLoading,
  updateDisplayedTable,
  updateSortMethod,
  updateStatusFilter,
  updateMapView,
  updateZoneZoom,
  updateZoneInfoData,
  updateCropTypesDict,
  updateCropTypesDropdownOptions,
  updateEditZoneInfo,
  updateEditZoneInfoValues,
} = zoneInfoSlice.actions;
export default zoneInfoSlice.reducer;
